import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import SectionHead from "../../assets/images/vectors/section-head.svg";

import "./sectionHeader.css";

const SectionHeader = ({
  customTopTitleClassNames,
  customTitleClassNames,
  art,
  artVector,
  topTitle,
  title,
  description,
}) => {
  return (
    <div className="dev-vg-section-header text-center text-ui-gray-plus-4">
      {!!art && (
        <div className="flex items-center justify-center mb-8">
          <img style={{ height: 96 }} alt="section-vector" src={artVector} />
        </div>
      )}
      {!!topTitle && (
        <h6
          className={classnames(
            "mb-2 text-brand-primary font-stylish font-normal leading-6 lg:tracking-wideer tracking-widen",
            {
              "text-2xl": !customTopTitleClassNames.fontSize,
              [customTopTitleClassNames?.fontSize]: customTopTitleClassNames?.fontSize,
              [customTopTitleClassNames?.lineHeight]: customTopTitleClassNames?.lineHeight,
            }
          )}
        >
          {topTitle}
        </h6>
      )}
      <h2
        className={classnames(
          "md:text-4xl max-w-3xl m-auto text-ui-gray-plus-4 font-bold text-2.5xl lg:text-4xl tracking-tightie lg:tracking-tightty",
          {
            "text-3xl": !customTitleClassNames.fontSize,
            [customTitleClassNames?.fontSize]: customTitleClassNames?.fontSize,
            [customTitleClassNames?.lineHeight]: customTitleClassNames?.lineHeight,
          }
        )}
      >
        {title}
      </h2>
      {description && (
        <p className="dev-vg-section-header__description lg:mt-8 mt-6 text-lg font-normal text-ui-gray-plus-4 tracking-tightsy body-large mx-auto">
          {description}
        </p>
      )}
    </div>
  );
};

SectionHeader.defaultProps = {
  artVector: SectionHead,
  customTopTitleClassNames: {},
  customTitleClassNames: {},
};

SectionHeader.propTypes = {
  customTopTitleClassNames: PropTypes.object,
  customTitleClassNames: PropTypes.object,
  art: PropTypes.bool,
  artVector: PropTypes.any,
  topTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export { SectionHeader };
