import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SuccessIcon from "../../assets/icons/success.svg";
import ErrorIcon from "../../assets/icons/error.svg";

import "./alertModal.css";

const AlertModal = ({ type, show, title, content, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="dev-vg-alert-modal">
      <div className="dev-vg-alert-modal__container flex flex-col items-center text-center">
        <div
          className={classnames(
            "dev-vg-alert-modal__container__icon text-7xl",
            {
              "text-semantic-error": type === "error",
              "text-semantic-success": type === "success",
            }
          )}
        >
          {type === "error" ? (
            <img alt="error" src={ErrorIcon} />
          ) : (
            <img alt="success" src={SuccessIcon} />
          )}
        </div>
        <div className="dev-vg-alert-modal__container__title mt-8 text-ui-gray-plus-4 font-bold text-3xl">
          {title}
        </div>
        {content && (
          <div className="dev-vg-alert-modal__container__content mt-4 text-ui-gray-plus-4 font-normal text-lg">
            {content}
          </div>
        )}
        <button
          onClick={onClose}
          className="dev-vg-alert-modal__container__action absolute top-4 right-4"
        >
          <i className="vg-x text-ui-gray-plus-3" />
        </button>
      </div>
    </div>
  );
};

AlertModal.defaultProps = {
  onClose: () => {},
};

AlertModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.any,
  onClose: PropTypes.func,
  type: PropTypes.string,
};

export { AlertModal };
