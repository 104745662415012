import React from "react";
import PropTypes from "prop-types";

import "./hero.css";

const Hero = ({ subTitle, title, description, arts, children, action }) => {
  return (
    <div className="dev-vg-hero text-center">
      {subTitle && (
        <h6
          className="mb-4 md:mb-2 text-brand-primary font-stylish text-lg font-normal leading-6"
          style={{ letterSpacing: "1px" }}
        >
          {subTitle}
        </h6>
      )}
      <h1 className="text-ui-gray-plus-4 md:text-4xl font-bold lg:text-5xl text-3xl md:tracking-tightmost tracking-tighteer leading-10 md:leading-14 lg:leading-18">
        {title}
      </h1>
      {description && (
        <p className="dev-vg-hero__description lg:mt-6 md:mt-8 mt-4 text-ui-gray-plus-4 leading-8 mx-auto text-lg font-normal">
          {description}
        </p>
      )}
      {arts && (
        <div className="text-5xl text-content-lite relative">{arts}</div>
      )}
      {action}
      {children && (
        <div className="dev-vg-hero__children flex items-center justify-center flex-wrap gap-2 md:gap-12 lg:mt-4 md:mt-9 mt-7">
          {children}
        </div>
      )}
    </div>
  );
};

Hero.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.oneOfType[(PropTypes.string, PropTypes.element)],
  description: PropTypes.oneOfType(PropTypes.string, PropTypes.element),
  arts: PropTypes.element,
  children: PropTypes.element,
  action: PropTypes.element,
};

export { Hero };
