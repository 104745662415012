import React from "react";
import PropTypes from "prop-types";

const SecondaryHeader = ({ title, description }) => {
  return (
    <div className="lg:mb-16 mb-8">
      <h2 className="text-center text-ui-gray-plus-4 md:text-4xl text-2.25xl leading-9 font-bold lg:leading-14 lg:tracking-tightty tracking-tightie">
        {title}
      </h2>
      {description && (
        <p className="md:mt-16 lg:mt-12 mt-6 body-large lg:px-60 px-0 text-ui-gray-plus-4 leading-8 font-normal tracking-tightsy">
          {description}
        </p>
      )}
    </div>
  );
};

SecondaryHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export { SecondaryHeader };
