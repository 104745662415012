import { Link, StaticQuery, graphql } from "gatsby";
import React from "react";

import { useAuth } from "../hooks";
import { Logo } from "./common";

function Footer() {
  const { isAuthenticated } = useAuth();

  return (
    <footer className="relative">
      <span className="absolute top-0 left-0 text-background-white">
        {/* 64px already occupied */}
      </span>
      <nav
        style={{ maxWidth: 1252 }}
        className="lg:pt-16 mx-auto pt-8 md:pt-16 lg:pb-20 grid grid-cols-6 gap-4 pl-6 lg:pl-0 pr-6 lg:pr-0 pb-6"
      >
        <div className="lg:col-span-2 md:col-span-6 col-span-6 pr-0 text-center md:text-center lg:text-left mb-12 md:mb-12 lg:mb-0 w-full md:w-96 md:m-auto ml-0 md:ml-0 lg:ml-0">
          <div className="lg:mb-8 mb-4 inline-block">
            <Logo />
          </div>
          <p className="font-normal text-lg text-ui-gray-plus-4 leading-8 tracking-tightsy">
            Carefully crafted graphic assets to kickstart your projects. Save
            efforts, time, money and focus on scaling your business.
          </p>
          <p className="pt-6 hidden lg:block">
            <small className="text-ui-gray-plus-3 font-normal text-pre-tiny leading-6 tracking-tightly">
              &copy; 2019 - {new Date().getUTCFullYear()}, vackground.com
            </small>
          </p>
        </div>
        <div className="col-span-3 md:col-span-1 mb-8 lg:mb-0">
          <h5 className="md:mb-8 mb-4 text-ui-gray-plus-4 text-pre-lg leading-7 tracking-tightsy">
            Website
          </h5>
          <ul className="body-large">
            <li>
              <Link
                className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4"
                to="/"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4"
                to="/about"
              >
                About
              </Link>
            </li>
            {/* <li>
              <Link className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4" to="/blog">Blog</Link>
            </li> */}
            {/* <li>
              <Link className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4" to="/affiliate">Affiliate</Link>
            </li> */}
            <li>
              <Link
                className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4"
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-span-3 md:col-span-2 lg:col-span-1 md:text-left text-right  mb-8 lg:mb-0">
          <h5 className="md:mb-8 mb-4 text-ui-gray-plus-4 text-pre-lg leading-7 tracking-tightsy">
            Products
          </h5>
          <StaticQuery
            query={graphql`
              query CategoryProductCount {
                allStrapiCategory {
                  edges {
                    node {
                      data {
                        attributes {
                          Name
                          URL
                          ShowOnFooter
                          products {
                            data {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={({
              allStrapiCategory: {
                edges: [
                  {
                    node: { data },
                  },
                ],
              },
            }) => (
              <ul className="body-large">
                {data
                  .filter((item) => item.attributes.ShowOnFooter)
                  .map((item) => (
                    <li key={item.attributes.URL}>
                      <Link
                        className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4"
                        to={`/category/${item.attributes.URL}`}
                      >
                        {item.attributes.Name}{" "}
                        <span className="text-ui-gray-plus-2">
                          ({item.attributes.products.data.length})
                        </span>
                      </Link>
                    </li>
                  ))}
              </ul>
            )}
          />
        </div>
        <div className="col-span-3 md:col-span-2 lg:col-span-1">
          <h5 className="md:mb-8 mb-4 text-ui-gray-plus-4 text-pre-lg leading-7 tracking-tightsy">
            Legal
          </h5>
          <ul className="body-large">
            <li>
              <Link
                className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4"
                to="/license"
              >
                License
              </Link>
            </li>
            <li>
              <Link
                className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4"
                to="/terms"
              >
                Terms
              </Link>
            </li>
            <li>
              <Link
                className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4"
                to="/privacy"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4"
                to="/refund"
              >
                Refund Policy
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-span-3 md:col-span-1 md:text-left text-right">
          <h5 className="md:mb-8 mb-4 text-ui-gray-plus-4 text-pre-lg leading-7 tracking-tightsy">
            Others
          </h5>
          <ul className="body-large">
            <li>
              <Link
                className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4"
                to="/updates"
              >
                Updates
              </Link>
            </li>
            {/* <li>
              <Link className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4" to="/newsletter">Subscribe</Link>
            </li> */}
            <li>
              <Link
                className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4"
                to="/faq"
              >
                FAQs
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-span-6 flex items-center md:justify-between justify-center flex-wrap lg:flex-nowrap md:mt-16 mt-12 mb-12 md:mb-0">
          <div className="flex items-center mr-2 w-full lg:w-auto justify-center lg:justify-start">
            <div className="font-normal text-lg text-ui-gray-plus-3 tracking-tightsy leading-8">
              Need Help?{" "}
              <Link to="/contact" className="text-ui-gray-plus-4">
                Contact
              </Link>
            </div>
            <i className="vg-arrow-right text-ui-gray-plus-4 ml-2" />
          </div>
          <div className="text-ui-gray-plus-3 lg:flex hidden items-center gap-8 w-full lg:w-auto justify-center lg:justify-start">
            <a
              target="__blank"
              rel="noopener noreferrer"
              href="https://facebook.com/vackground/"
            >
              <i className="vg-facebook text-ui-gray-plus-3" />
            </a>
            <a
              target="__blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/vackgroundhq/"
            >
              <i className="vg-instagram text-ui-gray-plus-3" />
            </a>
            <a
              target="__blank"
              rel="noopener noreferrer"
              href="https://twitter.com/vackground"
            >
              <i className="vg-twitter text-ui-gray-plus-3" />
            </a>
            <a
              target="__blank"
              rel="noopener noreferrer"
              href="https://www.pinterest.com/vackground/"
            >
              <i className="vg-pinterest text-ui-gray-plus-3" />
            </a>
            <a
              target="__blank"
              rel="noopener noreferrer"
              href="https://unsplash.com/@vackground"
            >
              <i className="vg-unsplash text-ui-gray-plus-3" />
            </a>
            <a
              target="__blank"
              rel="noopener noreferrer"
              href="https://www.behance.net/vackground"
            >
              <i className="vg-behance text-ui-gray-plus-3" />
            </a>
            <a
              target="__blank"
              rel="noopener noreferrer"
              href="https://www.producthunt.com/@shemul"
            >
              <i className="vg-product-hunt text-ui-gray-plus-3" />
            </a>
            <a
              target="__blank"
              rel="noopener noreferrer"
              href="https://dribbble.com/hishahadat"
            >
              <i className="vg-dribbble text-ui-gray-plus-3" />
            </a>
          </div>
          <div className="flex items-center lg:justify-start justify-center w-full lg:w-auto">
            <div className="font-normal text-lg text-ui-gray-plus-3 mr-2">
              Returning Buyer?{" "}
              <Link
                to={isAuthenticated ? "/dashboard" : "/login"}
                className="text-ui-gray-plus-4"
              >
                {isAuthenticated ? "Dashboard" : "Login"}
              </Link>
            </div>
            <i className="vg-arrow-right" />
          </div>
        </div>
        <div className="lg:hidden block col-span-6 text-center lg:text-left lg:mb-6 mb-0 w-full lg:w-auto">
          <p className="text-ui-gray-plus-2 text-tiny leading-6">
            <small className="text-ui-gray-plus-2 tracking-tightly leading-6 font-normal text-pre-tiny">
              &copy; 2019 - {new Date().getUTCFullYear()}, vackground.com
            </small>
          </p>
        </div>
      </nav>
    </footer>
  );
}

export default Footer;
