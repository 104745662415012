import React from "react";
import PropTypes from "prop-types";

const TeamCard = ({ name, title }) => {
  return (
    <div className="text-center relative pt-16">
      <div className="absolute gradient-text gradient-text-name font-bold text-brand-primary">
        {name[0]}
      </div>
      <div className="relative z-10">
        <h4 className="font-bold">{name}</h4>
        <p className="body-large">{title}</p>
      </div>
    </div>
  );
};

TeamCard.defaultProps = {
  name: "Shahadat Rahman",
  title: "Founder, Designer",
};

TeamCard.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
};

export { TeamCard };
