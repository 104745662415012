import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import classnames from "classnames";

const Directiontext = ({ className, text, noMobile, noTablet, icon, to }) => {
  return (
    <Link
      to={to}
      className={classnames("flex items-center justify-center cursor-pointer", {
        "md:flex hidden": noMobile,
        "flex: md:hidden lg:flex": noTablet,
        [className]: className,
      })}
    >
      <div className="mr-2 text-lg font-bold text-ui-gray-plus-4">{text}</div>
      <i
        className={classnames("text-ui-gray-plus-4", {
          [icon]: icon,
        })}
      />
    </Link>
  );
};

Directiontext.defaultProps = {
  icon: "vg-arrow-right",
};

Directiontext.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  noMobile: PropTypes.bool,
  noTablet: PropTypes.bool,
  to: PropTypes.string,
  className: PropTypes.string,
};

export { Directiontext };
