import React from "react";
import classnames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const Breadcrumbs = ({ lists }) => {
  return (
    <div className="flex items-center">
      {lists.map((list, index) => (
        <div className="flex items-center" key={list.to}>
          <Link
            className={classnames(
              "text-ui-gray-plus-3 font-normal text-pre-tiny tracking-tightly leading-6",
              {
                "text-ui-gray-plus-4": index === lists.length - 1,
              }
            )}
            to={list.to}
          >
            {list.title}
          </Link>
          {index !== lists.length - 1 && (
            <span className="transform -rotate-90 mx-6">
              <i
                style={{ fontSize: 8 }}
                className="vg-chevron-down text-ui-gray-plus-3"
              />
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  lists: PropTypes.array,
};

export { Breadcrumbs };
