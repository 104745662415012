import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import "./orderItem.css";

const OrderItem = ({ image, title, productCode, email }) => {
  return (
    <div className="dev-vg-order-item rounded-lg bg-background-white relative mt-16">
      <div className="dev-vg-order-item__thumbnail">
        <GatsbyImage className="object-cover" image={getImage(image)} />
      </div>
      <div className="pt-24 px-12 pb-6 text-center text-ui-gray-plus-4 font-bold text-lg">
        {title}
      </div>
      <div className="flex w-full border-background-bright border-t">
        <a
          onClick={(e) => e.preventDefault()}
          className="lemonsqueezy-button rounded-bl-lg hover:bg-brand-primary flex-1 group hover:text-background-white py-4 px-12 border-background-bright border-r transition-all"
          href={`https://vackground.lemonsqueezy.com/checkout/buy/${productCode}?embed=1&checkout[email]=${email}`}
        >
          <i className="group-hover:text-background-white vg-file" />
          <span className="ml-2 group-hover:text-background-white">
            Receipt
          </span>
        </a>
        <a
          onClick={(e) => e.preventDefault()}
          className="lemonsqueezy-button rounded-br-lg hover:bg-brand-primary flex-1 group hover:text-background-white py-4 px-12 transition-all"
          href={`https://vackground.lemonsqueezy.com/checkout/buy/${productCode}?embed=1&checkout[email]=${email}`}
        >
          <i className="vg-download group-hover:text-background-white" />
          <span className="ml-2 group-hover:text-background-white">
            Download
            {/* https://api.lemonsqueezy.com/v1/variants/3339/files LemonFileID */}
          </span>
        </a>
      </div>
    </div>
  );
};

OrderItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  productCode: PropTypes.string,
  email: PropTypes.string,
};

export { OrderItem };
