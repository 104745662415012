import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import "./iconBox.css";

const IconBox = ({ icon, title, children: description, className }) => {
  return (
    <div
      className={classnames(
        "bg-background-white py-12 px-8 rounded-xl text-2xl relative flex-1 text-center text-ui-gray-plus-4",
        {
          [className]: className,
        }
      )}
    >
      <div
        style={{
          width: 48,
          height: 48,
          fontSize: 20,
          top: -25,
          left: "50%",
          transform: "translate(-50%)",
        }}
        className="bg-brand-primary text-background-white flex items-center justify-center rounded-full absolute"
      >
        <i
          className={classnames("text-brand-primary-on", {
            [icon]: icon,
          })}
        />
      </div>
      <div className="font-bold lg:text-2xl text-xl text-ui-gray-plus-4 lg:leading-9 leading-8 tracking-tightsy lg:tracking-tighteer">
        {title}
      </div>
      {description && (
        <div className="dev-vg-icon-box__description body-large mt-4 text-lg font-normal text-ui-gray-plus-4 tracking-tightsy leading-8">
          {description}
        </div>
      )}
    </div>
  );
};

IconBox.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.element,
  description: PropTypes.string,
  className: PropTypes.string,
};

export { IconBox };
