import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useNonInitialEffect } from "../../helpers";
import { Link } from "gatsby";

const Input = ({
  componentType,
  disabled,
  name,
  className,
  label,
  type,
  placeholder,
  value,
  fullWidth,
  onChange,
  minLength,
  maxLength,
  pattern,
  patternErrorMessage,
  onValidate,
  minLengthErrorMessage,
  maxLengthErrorMessage,
  valid,
  note,
  tooltip,
  showForgotLink,
}) => {
  const INITIAL_MESSAGE = {
    type: "",
    text: "",
  };

  const [state, setState] = useState({
    passwordVisibility: false,
    message: { ...INITIAL_MESSAGE },
    validation: {
      isLengthValid: true,
      isPatternValid: true,
    },
  });

  useNonInitialEffect(() => {
    triggerValidation(false);
  }, [value]);

  const triggerValidation = (triggerMessage = false) => {
    if (pattern) {
      const regex = new RegExp(pattern);
      const isValid = regex.test(String(value));

      if (!isValid) {
        setState({
          ...state,
          message: triggerMessage
            ? {
                type: "error",
                text: patternErrorMessage || `${label} is not valid`,
              }
            : { ...INITIAL_MESSAGE },
          validation: {
            ...state.validation,
            isPatternValid: false,
          },
        });
      } else {
        setState({
          ...state,
          message: { ...INITIAL_MESSAGE },
          validation: { ...state.validation, isPatternValid: true },
        });
      }
    }

    if (minLength || maxLength) {
      const trimmedString = String(value).trim();

      if (minLength && maxLength) {
        const isMinLengthValid = trimmedString.length >= minLength;
        const isMaxLengthValid = trimmedString.length <= maxLength;
        const isValid = isMinLengthValid && isMaxLengthValid;

        if (isValid) {
          setState({
            ...state,
            message: { ...INITIAL_MESSAGE },
            validation: { ...state.validation, isLengthValid: true },
          });
        } else {
          const text = isMinLengthValid
            ? maxLengthErrorMessage ||
              `${label} should be less than ${maxLength} characters`
            : minLengthErrorMessage ||
              `${label} should be more than ${minLength} characters`;

          setState({
            ...state,
            message: triggerMessage
              ? {
                  type: "error",
                  text,
                }
              : { ...INITIAL_MESSAGE },
            validation: {
              ...state.validation,
              isLengthValid: false,
            },
          });
        }
      } else if (minLength) {
        const isValid = trimmedString.length >= minLength;

        if (isValid) {
          setState({
            ...state,
            message: { ...INITIAL_MESSAGE },
            validation: { ...state.validation, isLengthValid: true },
          });
        } else {
          const text =
            minLengthErrorMessage ||
            `${label} should be more than ${minLength} characters`;

          setState({
            ...state,
            message: triggerMessage
              ? {
                  type: "error",
                  text,
                }
              : { ...INITIAL_MESSAGE },
            validation: {
              ...state.validation,
              isLengthValid: false,
            },
          });
        }
      } else if (maxLength) {
        const isValid = trimmedString.length <= maxLength;

        if (isValid) {
          setState({
            ...state,
            message: { ...INITIAL_MESSAGE },
            validation: { ...state.validation, isLengthValid: true },
          });
        } else {
          const text =
            maxLengthErrorMessage ||
            `${label} should be less than ${maxLength} characters`;

          setState({
            ...state,
            message: triggerMessage
              ? {
                  type: "error",
                  text,
                }
              : { ...INITIAL_MESSAGE },
            validation: {
              ...state.validation,
              isLengthValid: false,
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    onValidate(
      name,
      state.validation.isLengthValid && state.validation.isPatternValid
    );
  }, [state.validation.isLengthValid, state.validation.isPatternValid]);

  const onPasswordVisibility = (e) => {
    e.preventDefault();

    setState({ ...state, passwordVisibility: !state.passwordVisibility });
  };

  return (
    <div
      className={classnames({
        relative: valid || tooltip || componentType === "inline",
        group: tooltip,
      })}
    >
      {label && componentType !== "inline" && (
        <label
          className={classnames(
            "inline-block body-medium text-ui-gray-plus-4 mb-2 font-normal text-tiny leading-6 tracking-tightly",
            { "flex items-center justify-between": type === "password" }
          )}
          htmlFor={name}
        >
          <span className="inline-flex items-center">
            {label}{" "}
            {showForgotLink && (
              <ul className="list-disc">
                <li className="ml-8 text-ui-gray-plus-3">
                  <Link
                    className="text-ui-gray-plus-3 font-normal tracking-tightly text-pre-tiny leading-6"
                    to="/reset-password"
                  >
                    Forgot?
                  </Link>
                </li>
              </ul>
            )}
          </span>
          {type === "password" && (
            <button onClick={onPasswordVisibility}>
              {state.passwordVisibility ? "Hide" : "Show"}
            </button>
          )}
        </label>
      )}
      <input
        style={{ backgroundColor: "transparent" }}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => triggerValidation(true)}
        type={state.passwordVisibility ? "text" : type}
        className={classnames("block px-6 py-4 rounded-lg border", {
          [className]: className,
          "border-ui-gray-plus-1": !state.message?.type,
          "border-semantic-error": state.message?.type === "error",
          "border-semantic-success": state.message?.type === "success" || valid,
          "w-full": fullWidth,
        })}
        name={name}
        value={value}
        placeholder={placeholder}
      />
      {valid && (
        <span className="absolute right-4 top-1/2 transform -translate-y-2/4">
          <i className="vg-circle-check text-semantic-success" />
        </span>
      )}
      {componentType === "inline" && state.message.type ? (
        <span className="absolute right-4 top-1/2 transform -translate-y-2/4 group">
          <i
            className={classnames({
              "vg-circle-check text-semantic-success":
                state.message?.type === "success" || valid,
              "vg-x-circle text-semantic-error":
                state.message?.type === "error",
            })}
          />
          <span
            className={classnames(
              "absolute right-0 top-1/2 transform -translate-y-1/2 whitespace-nowrap text-background-white py-1 px-4 rounded-full pointer-events-none text-sm opacity-0 group-hover:opacity-100 transition-all",
              {
                "bg-semantic-error": state.message?.type === "error",
                "bg-semantic-success":
                  state.message?.type === "success" || valid,
              }
            )}
          >
            {state.message.text}
          </span>
        </span>
      ) : null}
      {(state.message.type || valid) && componentType !== "inline" && (
        <span
          className={classnames({
            none: !state.message.type,
            "inline-block mt-2 font-normal text-tiny": state.message,
            "text-semantic-success-plus-1":
              state.message?.type === "success" || valid,
            "text-semantic-error-plus-1": state.message?.type === "error",
          })}
        >
          {note || state.message.text}
        </span>
      )}
      {tooltip}
    </div>
  );
};

Input.defaultProps = {
  disabled: false,
  fullWidth: true,
  type: "text",
  onValidate: () => {},
};

Input.propTypes = {
  componentType: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  pattern: PropTypes.string,
  onValidate: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  patternErrorMessage: PropTypes.string,
  minLengthErrorMessage: PropTypes.string,
  maxLengthErrorMessage: PropTypes.string,
  valid: PropTypes.bool,
  note: PropTypes.string,
  tooltip: PropTypes.element,
  showForgotLink: PropTypes.bool,
};

export { Input };
