import { graphql, StaticQuery, Link } from "gatsby";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { useAuth } from "../hooks/useAuth";

import "./header.css";
import { Search, Logo } from "./common";
import { useAuth } from "../hooks";

function Header({ customNav, hideMobileNav }) {
  const [isExpanded, toggleExpansion] = useState(false);
  const { isAuthenticated, state, logout } = useAuth();
  useEffect(() => {
    if (typeof window !== "undefined") {
      const cc = window.initCookieConsent();
      cc.run({
        current_lang: "en",
        autoclear_cookies: true, // default: false
        page_scripts: true, // default: false

        // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        // delay: 0,                               // default: 0
        // auto_language: null                     // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: false,                   // default: false
        // hide_from_bots: false,                  // default: false
        // remove_cookie_tables: false             // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        // onFirstAction: function (user_preferences, cookie) {
        //   // callback triggered only once
        // },

        // onAccept: function (cookie) {
        //   // ...
        // },

        // onChange: function (cookie, changed_preferences) {
        //   // ...
        // },

        languages: {
          en: {
            consent_modal: {
              title: "Can we store cookies?",
              description:
                "It allows us to learn where you struggle when browsing our site and improve it for your next visit.",
              primary_btn: {
                text: "Store Cookies",
                role: "accept_all", // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: "Let Me Choose",
                role: "c-settings", // 'settings' or 'accept_necessary'
              },
            },
            settings_modal: {
              title: "Cookie preferences",
              save_settings_btn: "Save settings",
              accept_all_btn: "Accept all",
              reject_all_btn: "Reject all",
              close_btn_label: "Close",
              cookie_table_headers: [
                { col1: "Name" },
                { col2: "Domain" },
                { col3: "Expiration" },
                { col4: "Description" },
              ],
              blocks: [
                {
                  title: "Cookie usage 📢",
                  description:
                    'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/privacy" class="cc-link">privacy policy</a>.',
                },
                {
                  title: "Strictly necessary cookies",
                  description:
                    "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
                  toggle: {
                    value: "necessary",
                    enabled: true,
                    readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                  },
                },
                {
                  title: "Performance and Analytics cookies",
                  description:
                    "These cookies allow the website to remember the choices you have made in the past",
                  toggle: {
                    value: "analytics", // your cookie category
                    enabled: false,
                    readonly: false,
                  },
                  cookie_table: [
                    // list of all expected cookies
                    {
                      col1: "^_ga", // match all cookies starting with "_ga"
                      col2: "google.com",
                      col3: "2 years",
                      col4: "description ...",
                      is_regex: true,
                    },
                    {
                      col1: "_gid",
                      col2: "google.com",
                      col3: "1 day",
                      col4: "description ...",
                    },
                  ],
                },
                {
                  title: "Advertisement and Targeting cookies",
                  description:
                    "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you",
                  toggle: {
                    value: "targeting",
                    enabled: false,
                    readonly: false,
                  },
                },
                {
                  title: "More information",
                  description:
                    'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact">contact us</a>.',
                },
              ],
            },
          },
        },

        gui_options: {
          consent_modal: {
            layout: "box", // box/cloud/bar
            position: "bottom left", // bottom/middle/top + left/right/center
            transition: "slide", // zoom/slide
            swap_buttons: false, // enable to invert buttons
          },
          settings_modal: {
            layout: "box", // box/bar
            // position: 'left',           // left/right
            transition: "slide", // zoom/slide
          },
        },
      });
    }
  }, []);

  // const { isAuthenticated, state } = useAuth();

  return (
    <header
      style={{
        borderTopWidth: 3,
      }}
      className="border-brand-primary relative"
    >
      <div className="absolute bottom-0 left-0 text-background-white">
        {/* 40px already occupied */}
      </div>
      <div
        style={{ maxWidth: 1292 }}
        className={classnames(
          "flex flex-wrap items-center justify-between px-5 mx-auto p-6 md:py-5 md:px-8 lg:px-5 pl-5 md:pl-2 lg:pl-5",
          {
            "pl-5 md:pl-8": hideMobileNav,
          }
        )}
      >
        <div className="flex items-center md:w-auto w-full justify-between md:justify-start">
          <button
            className={classnames(
              "items-center block px-3 py-2 pr-0 md:pr-4 rounded lg:hidden",
              {
                "md:hidden": hideMobileNav,
              }
            )}
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="w-5 h-5 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
          <Logo />
          <Search className="flex md:hidden lg:flex" />
        </div>
        <span
          onClick={() => toggleExpansion(false)}
          className={classnames(
            "block md:hidden fixed w-full h-full bg-ui-gray-plus-4 top-0 left-0 opacity-0 pointer-events-none transition-all duration-200 z-10",
            {
              "opacity-80 pointer-events-auto": isExpanded,
            }
          )}
        />
        <nav
          className={classnames(
            "dev-vg-nav md:flex md:items-center md:w-auto flex flex-col p-6 md:p-0 md:flex-row fixed md:relative w-64 h-full md:h-auto bg-background-white top-0 left-0 z-20 border-brand-primary md:border-none transform transition-all duration-200",
            {
              "-translate-x-full md:translate-x-0": !isExpanded,
              "translate-x-0 md:translate-x-0": isExpanded,
              "flex md:hidden": customNav,
            }
          )}
        >
          <div className="md:hidden text-lg flex justify-between mb-3">
            <span>Menu</span>
            <button onClick={() => toggleExpansion(false)}>
              <i className="vg-x" />
            </button>
          </div>
          <StaticQuery
            query={graphql`
              query AllCategories {
                allStrapiCategory(sort: { fields: data___attributes___Name }) {
                  edges {
                    node {
                      id
                      data {
                        attributes {
                          Name
                          URL
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={({
              allStrapiCategory: {
                edges: [
                  {
                    node: { data: categories },
                  },
                ],
              },
            }) => (
              <>
                {[
                  {
                    route: `/about`,
                    title: `About`,
                    className: "block lg:block md:hidden",
                  },
                  {
                    route: `/products`,
                    title: `Backgrounds`,
                    className: "order-none md:order-2 lg:order-none",
                    subMenuItems: categories
                      .sort((categoryA, categoryB) =>
                        categoryA.attributes.Name.localeCompare(
                          categoryB.attributes.Name
                        )
                      )
                      .map((category) => ({
                        route: `/category/${category.attributes.URL}`,
                        title: category.attributes.Name,
                        isActive:
                          typeof window !== "undefined"
                            ? window.location.pathname ===
                              `/category/${category.attributes.URL}`
                            : false,
                      })),
                  },
                  {
                    route: `/contact`,
                    title: `Contact`,
                    className: "order-none md:order-3 lg:order-none",
                  },
                  isAuthenticated
                    ? {
                        route: `/account`,
                        title: `Hi, ${state.user.firstName}`,
                        subMenuItems: [
                          {
                            route: `/account`,
                            title: `Account`,
                          },
                          {
                            route: `/dashboard`,
                            title: `Dashboard`,
                          },
                          {
                            action: () => {
                              logout();
                            },
                            title: `Logout?`,
                          },
                        ],
                      }
                    : null,
                ]
                  .filter((item) => !!item)
                  .map((link) => (
                    <div
                      key={link.title}
                      className={classnames("dev-vg-menu-items", {
                        "relative group": link?.subMenuItems?.length,
                        [link.className]: link.className,
                      })}
                    >
                      <Link
                        className="mt-4 no-underline md:inline-block md:mt-0 md:ml-8 font-body flex items-center text-lg nav-item text-ui-gray-plus-4"
                        to={link.route}
                      >
                        {link.title}
                        {link?.subMenuItems?.length && (
                          <span className="ml-2">
                            <i className="vg-chevron-down" />
                          </span>
                        )}
                      </Link>
                      {link?.subMenuItems?.length && (
                        <div className="dev-vg-sub-menu-items flex flex-col bg-background-white rounded-lg shadow-xl absolute right-0 top-full x-transform x-translate-y-2 opacity-0 group-hover:opacity-100 min-w-max">
                          {link.subMenuItems.map((item, index) => (
                            <Link
                              onClick={(e) => {
                                if (item?.action) {
                                  e.preventDefault();

                                  item.action();
                                }
                              }}
                              key={item?.route || index}
                              className={classnames(
                                "mt-4 no-underline md:inline-block md:mt-0 font-body flex items-center text-lg nav-item px-8 py-1 hover:text-brand-primary",
                                {
                                  "text-brand-primary": item?.isActive,
                                  "pt-6": index === 0,
                                  "pb-6":
                                    index === link.subMenuItems.length - 1,
                                }
                              )}
                              to={item?.route || "/"}
                            >
                              {item.title}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
              </>
            )}
          />
          <Search isTabletVersion className="hidden md:flex lg:hidden" />
        </nav>
        {customNav}
      </div>
    </header>
  );
}

Header.propTypes = {
  customNav: PropTypes.element,
  hideMobileNav: PropTypes.bool,
};

export default Header;
