import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const Checkbox = ({ onChange, title, value }) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();

        onChange();
      }}
      className="inline-flex items-center gap-2"
    >
      <span
        style={{ height: 18, width: 18 }}
        className={classnames(
          "inline-flex items-center justify-center border-2 border-ui-gray-plus-4 rounded",
          {
            "bg-ui-gray-plus-4": value,
          }
        )}
        value={value}
      >
        {value && <i className="text-background-white vg-check" />}
      </span>
      <span className="text-ui-gray-plus-4 text-pre-tiny leading-6 tracking-tightly font-normal text-left">
        {title}
      </span>
    </button>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.element,
  value: PropTypes.bool,
};

export { Checkbox };
