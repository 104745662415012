import React from "react";
import PropTypes from "prop-types";

const ReasonCard = ({ no, title, description }) => {
  return (
    <div className="relative lg:pl-24">
      <div className="absolute lg:left-0 lg:top-0 bottom-full transform translate-y-8 lg:translate-y-0 text-left gradient-text text-7xl font-bold leading-26">
        {no}
      </div>
      <h3 className="font-bold lg:text-3xl text-1.5xl lg:leading-10 leading-8 lg:tracking-tighteer tracking-tightsy lg:mb-4 mb-2 text-ui-gray-plus-4">
        {title}
      </h3>
      <p className="font-normal text-lg leading-8 tracking-tightsy text-ui-gray-plus-4">
        {description}
      </p>
    </div>
  );
};

ReasonCard.defaultProps = {
  no: 0,
  title: "Sample Title",
  description:
    "Every Vackground product has a free download version with unlimited use of personal and commercial",
};

ReasonCard.propTypes = {
  no: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
};

export { ReasonCard };
