import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const DosDont = ({ children, yes, no, mail, info }) => {
  return (
    <div className="flex text-lg leading-8 items-start">
      <span
        style={{ minHeight: 32, minWidth: 32 }}
        className={classnames(
          "mr-4 inline-flex items-center justify-center rounded-full absolute -left-12",
          {
            "text-semantic-success bg-semantic-success-minus-2": yes,
            "text-semantic-error bg-semantic-error-minus-2": no,
            " text-brand-primary bg-brand-primary-minus-1": mail,
            "text-semantic-info bg-semantic-info-minus-2": info,
          }
        )}
      >
        <i
          className={classnames({
            "vg-x text-semantic-error": no,
            "vg-check text-semantic-success": yes,
            "vg-mail text-brand-primary": mail,
            "vg-arrow-right text-semantic-info": info,
          })}
        />
      </span>
      {children}
    </div>
  );
};

DosDont.defaultProps = {
  no: false,
  mail: false,
  yes: false,
  info: false,
};

DosDont.propTypes = {
  children: PropTypes.any,
  yes: PropTypes.bool,
  no: PropTypes.bool,
  mail: PropTypes.bool,
  info: PropTypes.bool,
};

export { DosDont };
