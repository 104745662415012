import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import classnames from "classnames";

import Header from "./header";
import Footer from "./footer";

import "./layout.css";

function Layout({
  children,
  header,
  footer,
  customNav,
  screenHeight,
  hideMobileNav,
}) {
  return (
    <div
      className={classnames(
        "dev-vg-layout flex flex-col min-h-screen font-custom bg-background-bright"
      )}
    >
      <Helmet>
        <script>{`window.lemonSqueezyAffiliateConfig = { store: "vackground" };`}</script>
        <script src="https://lmsqueezy.com/affiliate.js" defer></script>
        <script
          src="https://beamanalytics.b-cdn.net/beam.min.js"
          data-token="4b3077ae-384c-49c0-9dd4-331275317fd5"
          async
        ></script>
      </Helmet>
      {header && <Header customNav={customNav} hideMobileNav={hideMobileNav} />}

      <main
        className={classnames(
          "dev-vg-layout__main w-full max-w-6xl px-6 md:px-8 mx-auto",
          {
            "flex-1 flex flex-col": screenHeight,
          }
        )}
      >
        {children}
      </main>

      {footer && <Footer />}
    </div>
  );
}

Layout.defaultProps = {
  header: true,
  footer: true,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.bool,
  footer: PropTypes.bool,
  customNav: PropTypes.element,
  screenHeight: PropTypes.bool,
  hideMobileNav: PropTypes.bool,
};

export default Layout;
