import React, { useState } from "react";
import classnames from "classnames";
import { navigate } from "gatsby";
import PropTypes from "prop-types";

import "./search.css";

const Search = ({ className, isTabletVersion }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);

  return (
    <div
      className={classnames(
        "dev-vg-header-search flex items-center text-ui-gray-plus-3 duration-200",
        {
          [className]: className,
          "dev-vg-header-search--tablet": isTabletVersion,
          "transition-none": !open,
          "fixed md:relative top-0 left-0 w-full bg-background-bright py-7 md:py-0 px-6 md:px-0 transition-all": open,
        }
      )}
    >
      <button
        onClick={() => {
          if (open) {
            // set focus on the input field
          } else {
            setOpen(true);
          }
        }}
        className="inline-block lg:hidden w-9 relative top-auto md:top-1 lg:top-auto"
      >
        <i className="vg-search" />
      </button>
      <i className="hidden lg:inline-block vg-search text-ui-gray-plus-3" />
      <input
        className={classnames(
          "border-brand-primary text-lg lg:block font-normal leading-8",
          {
            hidden: !open,
            "flex-1": open,
          }
        )}
        placeholder="Search all assets"
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            navigate(`/search?q=${searchTerm}`);
          }
        }}
      />
      <button
        className={classnames("lg:hidden", {
          "inline-block": open,
          hidden: !open,
        })}
        onClick={() => setOpen(false)}
      >
        <i className="vg-x" />
      </button>
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  isTabletVersion: PropTypes.bool,
};

export { Search };
