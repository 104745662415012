import React from "react";
import PropTypes from "prop-types";
import { FlipCard } from "./flipCard";
import { SectionHeader } from "./sectionHeader";

const FaqItem = ({ title, faqs, artVector }) => {
  return (
    <>
      <div className="lg:mb-20 mb-10">
        <SectionHeader art={!!artVector} artVector={artVector} title={title} />
      </div>
      <div className="lg:px-48 px-0">
        {faqs.map((faq, index) => (
          <FlipCard key={index} title={faq.question}>
            {faq.answer}
          </FlipCard>
        ))}
      </div>
    </>
  );
};

FaqItem.propTypes = {
  title: PropTypes.string,
  faqs: PropTypes.array,
  artVector: PropTypes.element,
};

export { FaqItem };
