import React from "react";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";

import CardImage from "../../assets/images/card-image.jpg";
import { createRipple } from "../../helpers";

import "./blogCard.css";

const BlogCard = ({ title, to }) => {
  return (
    <Link
      onClick={(e) => {
        e.preventDefault();

        createRipple(e);

        setTimeout(() => {
          navigate(to);
        }, 200);
      }}
      to={to}
      className="dev-vg-blog-card bg-ui-gray-minus-4 rounded-lg p-6 cursor-pointer transform hover:-translate-y-2 transition-all group overflow-hidden m-auto"
    >
      <div>
        <img
          className="w-full object-cover opacity-100 group-hover:opacity-90 transition-all"
          alt="blog-thumbnail"
          src={CardImage}
        />
      </div>
      <div className="text-ui-gray-plus-4 font-bold mt-4">{title}</div>
    </Link>
  );
};

BlogCard.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
};

export { BlogCard };
