import React, { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Moustache from "../../assets/images/vectors/moustache.svg";

import "./button.css";
import { Link } from "gatsby";
import { createRipple } from "../../helpers";

const Button = ({
  className,
  onClick,
  text,
  tabletText,
  plainText,
  icon,
  type,
  badgeTitle,
  to,
  href,
  subText,
  hasMoustache,
  hasMinWidth,
  hasMoreWidth,
  iconRotate,
  disabled,
  isLoading,
  progress,
  contentInOneLine,
  preventDefault,
  hideIconOnTablet,
  forceTextInOneLine,
  newTab,
}) => {
  const buttonRef = useRef();
  const getTag = () => {
    if (href) {
      return "a";
    } else if (to) {
      return Link;
    }

    return "button";
  };
  const Tag = getTag();

  const handleOnClick = (e) => {
    if (preventDefault && !newTab) {
      e?.preventDefault();
    }

    createRipple(e);

    if (onClick) {
      onClick(e);
    }
  };

  const renderText = (text, textClass) => {
    if (!text) {
      return null;
    }

    return (
      <span
        className={classnames("btn-text text-brand-primary-on", {
          "whitespace-nowrap": forceTextInOneLine,
          [textClass]: textClass,
        })}
        data-content={plainText || text}
      >
        {text}
      </span>
    );
  };

  const renderContent = () => {
    return (
      <>
        {icon && (
          <i
            className={classnames("mr-2 text-brand-primary-on", {
              [icon]: icon,
              [`transform rotate-${iconRotate}`]: iconRotate,
              "hidden lg:inline": hideIconOnTablet,
            })}
          />
        )}
        {renderText(text, tabletText ? "hidden lg:inline-block" : "")}
        {renderText(tabletText, tabletText ? "inline-block lg:hidden" : "")}
        {subText && (
          <span
            className={classnames(
              "absolute w-full text-center text-sm tracking-normal text-brand-primary-on bottom-4 opacity-75",
              {
                "text-ui-gray-plus-3": disabled,
              }
            )}
          >
            {subText}
          </span>
        )}
      </>
    );
  };

  const renderBtn = () => {
    return (
      <Tag
        ref={buttonRef}
        to={to}
        href={href}
        target={newTab ? "__blank" : ""}
        rel="noopener noreferrer"
        onClick={handleOnClick}
        className={classnames(
          "dev-vg-btn px-12 py-5 rounded-xl text-brand-primary-on text-pre-lg leading-7 font-medium tracking-tightsy inline-flex items-center justify-center w-full md:w-auto overflow-hidden dev-vg-g-focus focus:outline-none",
          {
            "bg-brand-primary": !disabled,
            "bg-background-faded text-content-dark": type === "faded",
            "dev-vg-btn--has-min-width": hasMinWidth,
            "dev-vg-btn--has-more-width": hasMoreWidth,
            "dev-vg-btn--brand-gradient":
              !disabled && type === "brand-gradient",
            "relative pb-10": !!subText,
            "pointer-events-none bg-ui-gray-plus-1 text-ui-gray-plus-3": disabled,
            [className]: className,
            "whitespace-nowrap": contentInOneLine,
          }
        )}
      >
        <>
          {progress && (
            <span
              style={{ width: `${100 - progress}%` }}
              className="absolute h-full bg-ui-gray-plus-1 right-0 top-0 transition-all"
            />
          )}
          {isLoading ? (
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <>
              {renderContent()}
              {progress && renderContent(true)}
              {badgeTitle && (
                <span
                  style={{ top: "100%" }}
                  className="absolute w-full bg-note-alert text-note-alert-dark p-1 font-xs rounded-br-lg rounded-bl-lg uppercase font-bold	text-tiny"
                >
                  <span className="relative z-10">{badgeTitle}</span>
                  <span
                    style={{
                      top: "calc(100% - 10px)",
                      left: "calc(50% - 10px)",
                      height: 20,
                      width: 20,
                      transform: "rotate(45deg)",
                    }}
                    className="bg-note-alert absolute"
                  />
                </span>
              )}
            </>
          )}
        </>
      </Tag>
    );
  };

  if (hasMoustache) {
    return (
      <div
        className={classnames("dev-vg-btn-container", {
          relative: hasMoustache,
        })}
      >
        {renderBtn()}
        {!!hasMoustache && (
          <>
            <span
              className="absolute hidden md:block"
              style={{ top: "16px", left: "-56px" }}
            >
              <img
                style={{ minWidth: 32 }}
                alt="moustache-left"
                src={Moustache}
              />
            </span>
            <span
              className="absolute hidden md:block"
              style={{ top: "16px", right: "-56px" }}
            >
              <img
                style={{ minWidth: 32, transform: "rotate(180deg)" }}
                alt="moustache-right"
                src={Moustache}
              />
            </span>
          </>
        )}
      </div>
    );
  }

  return renderBtn();
};

Button.defaultProps = {
  type: "brand-gradient",
  hasMinWidth: true,
};

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  tabletText: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  badgeTitle: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  subText: PropTypes.string,
  hasMoustache: PropTypes.bool,
  contentInOneLine: PropTypes.bool,
  hasMinWidth: PropTypes.bool,
  hasMoreWidth: PropTypes.bool,
  iconRotate: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  progress: PropTypes.number,
  plainText: PropTypes.string,
  preventDefault: PropTypes.bool,
  hideIconOnTablet: PropTypes.bool,
  forceTextInOneLine: PropTypes.bool,
};

export { Button };
