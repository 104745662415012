import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const PasswordValidation = ({ password, name, email }) => {
  return (
    <div className="dev-vg-input-tooltip absolute bottom-full left-1/2 transform -translate-x-1/2 bg-background-white py-4 px-6 rounded-xl shadow-2xl w-72 opacity-0 group-hover:opacity-100 transition-all pointer-events-none">
      <h4 className="font-bold text-tiny text-ui-gray-plus-4 mb-4">
        A strong password requires
      </h4>
      <ul className="text-tiny text-ui-gray-plus-4 mb-4 font-normal">
        {[
          {
            text: "8 or more characters",
            isValid: () => {
              return String(password).length >= 8;
            },
          },
          {
            text: "Atleast 1 uppercase letter",
            isValid: () => {
              return /[A-Z]/.test(password);
            },
          },
          {
            text: "Atleast 1 lowercase letter",
            isValid: () => {
              return /[a-z]/.test(password);
            },
          },
          {
            text: "Atleast 1 special character",
            isValid: () => {
              return /\W|_/.test(password);
            },
          },
          {
            text: "Atleast 1 number",
            isValid: () => {
              return /\d/.test(password);
            },
          },
          {
            text: "Not same as your name or email",
            isValid: () => {
              return password !== name && password !== email;
            },
          },
          {
            text: "No spaces",
            isValid: () => {
              return !/\s/.test(password);
            },
          },
        ].map((item, index) => (
          <li
            className={classnames({
              "text-semantic-success": item.isValid(),
            })}
            key={index}
          >
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

PasswordValidation.propTypes = {
  password: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
};

export { PasswordValidation };
