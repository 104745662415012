import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import "./checkPoint.css";

const CheckPoint = ({ content, className }) => {
  return (
    <div
      className={classnames(
        "flex items-center dev-vg-checkpoint w-full lg:w-auto",
        { [className]: className }
      )}
    >
      <div className="dev-vg-checkpoint__icon-container bg-brand-primary-minus-1 inline-flex items-center justify-center rounded-full">
        <i className="vg-check text-lg text-brand-primary" />
      </div>
      <div className="ml-4 text-tiny leading-6 tracking-tightly text-ui-gray-plus-4">
        {content}
      </div>
    </div>
  );
};

CheckPoint.propTypes = {
  content: PropTypes.any,
  className: PropTypes.string,
};

export { CheckPoint };
