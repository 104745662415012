import React from "react";
import PropTypes from "prop-types";

import { Hero } from "./hero";

import "./confirmation.css";

const Confirmation = ({ subTitle, title, description, image, instruction }) => {
  return (
    <>
      <section className="dev-vg-confirmation mt-20">
        <Hero subTitle={subTitle} title={title} description={description} />
      </section>
      <section>
        <div className="relative">
          <img src={image} />
          <small className="dev-vg-confirmation__instruction absolute w-full text-center text-ui-gray-plus-3 font-normal text-tiny italic">
            {instruction}
          </small>
        </div>
      </section>
    </>
  );
};

Confirmation.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.element,
  image: PropTypes.string,
  instruction: PropTypes.string,
};

export { Confirmation };
