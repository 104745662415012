import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const ActionBox = ({ icon, children, description, className }) => {
  return (
    <div
      className={classnames(
        "bg-background-white py-12 px-8 text-2xl relative font-bold leading-9 rounded-xl",
        {
          [className]: className,
        }
      )}
    >
      <div
        style={{
          width: 48,
          height: 48,
          fontSize: 20,
          top: -25,
          left: "50%",
          transform: "translate(-50%)",
        }}
        className="bg-brand-primary text-background-white flex items-center justify-center rounded-full absolute"
      >
        <i className={classnames("text-brand-primary-on", { [icon]: icon })} />
      </div>
      <div className="text-center text-ui-gray-plus-4">{children}</div>
      {description && (
        <div className="body-large text-center mt-4">{description}</div>
      )}
    </div>
  );
};

ActionBox.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.element,
  description: PropTypes.string,
  className: PropTypes.string,
};

export { ActionBox };
