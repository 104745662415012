import React from "react";
import { Link } from "gatsby";

import logo from "../../assets/images/logo.svg";

const Logo = () => {
  return (
    <Link className="header-logo" to="/">
      <span className="text-brand-primary uppercase">Alpha</span>
      <img alt="vackground" src={logo} />
    </Link>
  );
};

export { Logo };
