import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useNonInitialEffect } from "../../helpers";

const Textarea = ({
  name,
  label,
  type,
  placeholder,
  value,
  fullWidth,
  onChange,
  rows,
  pattern,
  patternErrorMessage,
  minLength,
  minLengthErrorMessage,
  maxLength,
  maxLengthErrorMessage,
  onValidate,
}) => {
  const INITIAL_MESSAGE = {
    type: "",
    text: "",
  };

  const [state, setState] = useState({
    message: { ...INITIAL_MESSAGE },
    validation: {
      isLengthValid: true,
      isPatternValid: true,
    },
  });

  useNonInitialEffect(() => {
    triggerValidation(false);
  }, [value]);

  const triggerValidation = (triggerMessage = false) => {
    if (pattern) {
      const regex = new RegExp(pattern);
      const isValid = regex.test(String(value));

      if (!isValid) {
        setState({
          ...state,
          message: triggerMessage
            ? {
                type: "error",
                text: patternErrorMessage || `${label} is not valid`,
              }
            : { ...INITIAL_MESSAGE },
          validation: {
            ...state.validation,
            isPatternValid: false,
          },
        });
      } else {
        setState({
          ...state,
          message: { ...INITIAL_MESSAGE },
          validation: { ...state.validation, isPatternValid: true },
        });
      }
    }

    if (minLength || maxLength) {
      const trimmedString = String(value).trim();

      if (minLength && maxLength) {
        const isMinLengthValid = trimmedString.length >= minLength;
        const isMaxLengthValid = trimmedString.length <= maxLength;
        const isValid = isMinLengthValid && isMaxLengthValid;

        if (isValid) {
          setState({
            ...state,
            message: { ...INITIAL_MESSAGE },
            validation: { ...state.validation, isLengthValid: true },
          });
        } else {
          const text = isMinLengthValid
            ? maxLengthErrorMessage ||
              `${label} should be less than ${maxLength} characters`
            : minLengthErrorMessage ||
              `${label} should be more than ${minLength} characters`;

          setState({
            ...state,
            message: triggerMessage
              ? {
                  type: "error",
                  text,
                }
              : { ...INITIAL_MESSAGE },
            validation: {
              ...state.validation,
              isLengthValid: false,
            },
          });
        }
      } else if (minLength) {
        const isValid = trimmedString.length >= minLength;

        if (isValid) {
          setState({
            ...state,
            message: { ...INITIAL_MESSAGE },
            validation: { ...state.validation, isLengthValid: true },
          });
        } else {
          const text =
            minLengthErrorMessage ||
            `${label} should be more than ${minLength} characters`;

          setState({
            ...state,
            message: triggerMessage
              ? {
                  type: "error",
                  text,
                }
              : { ...INITIAL_MESSAGE },
            validation: {
              ...state.validation,
              isLengthValid: false,
            },
          });
        }
      } else if (maxLength) {
        const isValid = trimmedString.length <= maxLength;

        if (isValid) {
          setState({
            ...state,
            message: { ...INITIAL_MESSAGE },
            validation: { ...state.validation, isLengthValid: true },
          });
        } else {
          const text =
            maxLengthErrorMessage ||
            `${label} should be less than ${maxLength} characters`;

          setState({
            ...state,
            message: triggerMessage
              ? {
                  type: "error",
                  text,
                }
              : { ...INITIAL_MESSAGE },
            validation: {
              ...state.validation,
              isLengthValid: false,
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    onValidate(
      name,
      state.validation.isLengthValid && state.validation.isPatternValid
    );
  }, [state.validation.isLengthValid, state.validation.isPatternValid]);

  return (
    <div>
      <label
        className="inline-block body-medium text-ui-gray-plus-4 mb-2 text-tiny leading-6 tracking-tightly font-normal"
        htmlFor={name}
      >
        {label}
      </label>
      <textarea
        style={{ backgroundColor: "transparent" }}
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => triggerValidation(true)}
        type={type}
        className={classnames("block px-6 py-4 rounded-lg border", {
          "w-full": fullWidth,
          "border-ui-gray-plus-1": !state.message?.type,
          "border-semantic-error": state.message?.type === "error",
          "border-semantic-success": state.message?.type === "success",
        })}
        name={name}
        defaultValue={value}
        placeholder={placeholder}
        rows={rows}
      />
      {state.message.type && (
        <span
          className={classnames({
            none: !state.message.type,
            "inline-block mt-2 font-normal text-tiny": state.message,
            "text-semantic-success-plus-1": state.message?.type === "success",
            "text-semantic-error-plus-1": state.message?.type === "error",
          })}
        >
          {state.message.text}
        </span>
      )}
    </div>
  );
};

Textarea.defaultProps = {
  fullWidth: true,
  type: "text",
};

Textarea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  pattern: PropTypes.string,
  patternErrorMessage: PropTypes.string,
  minLength: PropTypes.Number,
  minLengthErrorMessage: PropTypes.string,
  maxLength: PropTypes.Number,
  maxLengthErrorMessage: PropTypes.string,
  onValidate: PropTypes.func,
};

export { Textarea };
