import React from "react";
import classnames from "classnames";
import { Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";

const Link = ({ href, to, children, className, ...props }) => {
  const classname = classnames(
    "text-brand-primary underline hover:no-underline",
    {
      [className]: className,
    }
  );

  if (href) {
    return (
      <a className={classname} href={href} {...props}>
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink className={classname} to={to} {...props}>
      {children}
    </GatsbyLink>
  );
};

Link.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

export { Link };
