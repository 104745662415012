import React from "react";
import PropTypes from "prop-types";

import "./descHeading.css";

const DescHeading = ({ title, subtitle }) => {
  return (
    <h4 className="dev-vg-desc-heading text-ui-gray-plus-4 font-bold text-center md:text-left lg:text-2.5xl text-xl leading-8 lg:leading-9 lg:tracking-tighteer tracking-tightsy">
      {title}
      <span className="hidden lg:block font-normal text-lg text-ui-gray-plus-4 leading-8 tracking-tightsy">
        {subtitle}
      </span>
    </h4>
  );
};

DescHeading.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export { DescHeading };
