import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import classnames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./updateCard.css";

const UpdateCard = ({
  tags,
  date,
  title,
  description,
  actionTitle,
  action,
  FeaturedImage,
}) => {
  return (
    <div className="dev-vg-update-card bg-background-white flex md:flex-nowrap flex-wrap rounded-xl relative md:static">
      <div className="dev-vg-update-card__image">
        <GatsbyImage
          className="object-cover md:rounded-tl-xl md:rounded-bl-xl rounded-bl-none rounded-tl-xl rounded-tr-xl md:rounded-tr-none h-full w-full"
          alt={title}
          image={getImage(FeaturedImage?.data?.attributes?.localFile)}
        />
      </div>
      <div className="p-8 flex flex-col justify-between">
        <div>
          <div
            className="text-xs uppercase inline-flex gap-2 mb-3 absolute md:static left-6"
            style={{ top: "264px" }}
          >
            {tags?.length &&
              tags.map((tag, index) => (
                <span
                  key={index}
                  className={classnames("px-3 py-1 rounded-sm leading-4", {
                    "bg-semantic-success-minus-2 text-semantic-success-plus-1":
                      tag.type === "update",
                    "bg-semantic-info-minus-2 text-semantic-info-plus-1":
                      tag.type === "new",
                    "bg-semantic-alert-minus-2 text-semantic-alert-plus-1":
                      tag.type === "fix",
                    "bg-semantic-error-minus-2 text-semantic-error-plus-1":
                      tag.type === "delete",
                  })}
                >
                  {tag.title}
                </span>
              ))}
            <span className="px-3 py-1 leading-4 bg-ui-gray-minus-3 text-ui-gray-plus-4 rounded-sm">
              {date}
            </span>
          </div>
          <div
            className="text-ui-gray-plus-4 md:text-3xl text-pre-lg font-bold mb-2 md:leading-10 leading-7"
            style={{ letterSpacing: "-1px" }}
          >
            {title}
          </div>
          <div className="text-ui-gray-plus-4 text-lg leading-8 tracking-tightsy">
            {description}
          </div>
        </div>
        {!!actionTitle && (
          <Link
            to={action}
            className="flex items-center font-bold text-lg mt-6 text-ui-gray-plus-4 leading-7 tracking-tightsy"
          >
            {actionTitle} <i className="vg-arrow-right ml-2" />
          </Link>
        )}
      </div>
    </div>
  );
};

UpdateCard.propTypes = {
  tags: PropTypes.array,
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.any,
  actionTitle: PropTypes.string,
  action: PropTypes.any,
  FeaturedImage: PropTypes.object,
};

export { UpdateCard };
