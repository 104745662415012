import React, { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const FlipCard = ({ title, children: content }) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className="overflow-hidden">
      <div
        style={{ borderColor: "#D1CEE0" }}
        role="button"
        tabIndex={0}
        onClick={() => setOpened(!opened)}
        className="flex items-center justify-between py-4 border-b group"
      >
        <div className="text-lg">{title}</div>
        <div className="flex items-center">
          <div className="hidden md:block bg-semantic-info-minus-2 text-semantic-info font-medium text-sm py-1 px-4 rounded-full mr-2 opacity-0 group-hover:opacity-100 transition-all">
            {!opened ? "Click to Read Full Answer" : "Click to Minimize"}
          </div>
          <div
            className={classnames("transition-all", {
              "transform rotate-0": !opened,
              "transform rotate-180": opened,
            })}
          >
            <i className="vg-chevron-down" />
          </div>
        </div>
      </div>
      <div
        className={classnames("max-h-0 transition-all text-lg", {
          "max-h-96 py-4": opened,
        })}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

FlipCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export { FlipCard };
